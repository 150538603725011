import { DESKTOP } from '@/styles/themes';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// 기본 컨테이너 스타일
export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 20px 0;
`;

export const ScrollContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;

// 단순한 무한 스크롤 애니메이션 - 약간의 여유 거리 추가
const scrollKeyframes = (distance: number) => keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-${distance + 20}px); } /* 20px 추가 여유 거리 */
`;

// 스크롤 컨텐츠 스타일 - 인라인 스타일로 직접 적용
export const ScrollContent = styled.div<{
  speed: number;
  containerWidth: number;
}>`
  display: flex;
  gap: 20px;

  ${(props) =>
    props.containerWidth > 0 &&
    css`
      animation: ${scrollKeyframes(props.containerWidth)} ${props.speed}s linear
        infinite;
      -webkit-animation: ${scrollKeyframes(props.containerWidth)}
        ${props.speed}s linear infinite;

      /* 성능 최적화 */
      will-change: transform;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
    `}
`;

// 이미지 래퍼 스타일
export const ImageWrapper = styled.div`
  flex-shrink: 0;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 267px;
    height: 140px;
  }

  ${DESKTOP} {
    img {
      width: 400px;
      height: 209px;
    }
  }
`;
