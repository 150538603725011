import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100dvh;
  background: black;
  margin: 0 auto;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
