import styled from '@emotion/styled';
import { bodyB, largeTitle } from '@teamsparta/stack-font';
import { bodyM, title2 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 16px;
  background: rgba(255, 255, 255, 0.8);

  backdrop-filter: blur(6px);
  gap: 40px;
  ${DESKTOP} {
    padding: 100px 24px;
    gap: 62px;
  }
`;

export const Titles = styled.div`
  text-align: center;
`;
export const SubTitle = styled.div`
  ${bodyB};
  color: ${vars.brand.default};
  ${DESKTOP} {
  }
`;
export const Title = styled.div`
  ${title2};
  color: ${vars.text.primary};
  margin: 8px 0 12px 0;
  ${DESKTOP} {
    margin: 10px 0 16px 0;
    ${largeTitle};
  }
`;

export const Description = styled.div`
  ${bodyM};
  color: ${vars.text.secondary};
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${DESKTOP} {
    width: 100%;
    max-width: 1152px;
    justify-content: space-between;
    flex-direction: row;
  }
`;
