import { motion } from 'framer-motion';

import { heroTitle } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';
import { DESKTOP } from '@/styles/themes';
import styled from '@emotion/styled';

export const Container = styled.div`
  height: 100svh;
  width: 100%;
  position: relative;
`;

export const Text = styled(motion.div)`
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: ${vars.text.primary};
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  margin: 0 auto;
  strong {
    color: ${vars.brand.default};
  }
  ${DESKTOP} {
    top: 50%;
    ${heroTitle};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 243px;
  margin: 0 auto;
  ${DESKTOP} {
    width: 410px;
  }
`;
