import { useCallback, useEffect, useRef, useState } from 'react';

import * as D from './data';

export function getStartingPointOfNthFullPageDiv(nth: number) {
  return (nth - 1) * window.innerHeight;
}

export function scrollToNthFullPageDiv(nth: number) {
  if (typeof window === 'undefined') {
    return;
  }

  window.scrollTo({
    top: getStartingPointOfNthFullPageDiv(nth),
    behavior: 'smooth',
  });
}

/**
 * 현재 스크롤 위치를 기반으로 스크롤 액션(wheel 또는 touch)이 발생한 div를 계산하여 반환
 * 참고) D.FIRST_DIV 와  D.SECOND_DIV 는 둘다 window.innerHeight 와 같은 height 값을 가지고 있음
 */
export const checkVisibleComponent = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const scrollPosition = window.scrollY;
  const viewportHeight = window.innerHeight;

  return scrollPosition < viewportHeight / 2 ? D.FIRST_DIV : D.SECOND_DIV;
};

export const ScrollDirection = {
  isScrollDown: (e: WheelEvent) => e.deltaY > 0,
  isScrollUp: (e: WheelEvent) => e.deltaY < 0,
};

/**
 * LookForDev div는 스크롤(wheelEvent)에 따라 다음 라인을 보여줘야 하는 div
 * 어떤 line을 보여줄 상태인지를 관리하는 hook
 * max가 2인 것은 총 세 줄의 line이 있기 때문
 */
export function useHandleDevDivScroll() {
  const [currentState, setCurrentState] = useState(0);

  function showNextLine() {
    setCurrentState((prev) => Math.min(2, prev + 1));
  }
  function showPreviousLine() {
    setCurrentState((prev) => Math.max(0, prev - 1));
  }

  function setFirstLine() {
    setCurrentState(0);
  }
  return { currentState, setFirstLine, showNextLine, showPreviousLine };
}

/**
 * 이상한 곳에 스크롤이 걸린 경우 맞춰주기
 */
function adjustScrollPosition(nth: number) {
  const criteria = getStartingPointOfNthFullPageDiv(nth);
  const current = window.scrollY;

  if (current !== criteria) {
    scrollToNthFullPageDiv(nth);
  }
}

export function useHandleHubFullPageScroll() {
  const containerRef = useRef<HTMLDivElement>(null);
  const { currentState, setFirstLine, showNextLine, showPreviousLine } =
    useHandleDevDivScroll();
  const lastExecutionRef = useRef<number>(0);
  const touchStartY = useRef<number | null>(null);
  const touchEndY = useRef<number | null>(null);

  const handleScrollAction = useCallback(
    (isScrollDown: boolean) => {
      const now = Date.now();
      if (now - lastExecutionRef.current >= D.THROTTLE_TIME) {
        lastExecutionRef.current = now;

        const currentDiv = checkVisibleComponent();
        /**
         * 첫번째 div에서 스크롤 이벤트가 발생했을 때
         */
        if (currentDiv === D.FIRST_DIV) {
          adjustScrollPosition(D.SECOND_DIV);
          setFirstLine();
          isScrollDown
            ? scrollToNthFullPageDiv(D.SECOND_DIV)
            : scrollToNthFullPageDiv(D.FIRST_DIV);
        }

        /**
         * 두번째 div에서 스크롤 이벤트가 발생했을 때
         */
        if (currentDiv === D.SECOND_DIV) {
          adjustScrollPosition(D.SECOND_DIV);

          /**
           * 스크롤 Up 액션 : 이전 라인으로 돌아가거나, (이미 첫번째 라인인 경우) 이전 div로 이동
           */
          if (!isScrollDown) {
            return currentState > 0
              ? showPreviousLine()
              : scrollToNthFullPageDiv(D.FIRST_DIV);
          }

          /**
           * 스크롤 Down 액션 : 다음 라인으로 넘어가거나, (이미 마지막 라인인 경우) 다음 div로 이동
           */
          return currentState < 2
            ? showNextLine()
            : scrollToNthFullPageDiv(D.THIRD_DIV);
        }
      }
    },
    [currentState, setFirstLine, showNextLine, showPreviousLine],
  );

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      e.preventDefault();
      handleScrollAction(ScrollDirection.isScrollDown(e));
    },
    [handleScrollAction],
  );

  const handleTouchStart = useCallback((e: TouchEvent) => {
    touchStartY.current = e.touches[0].clientY;
  }, []);

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      touchEndY.current = e.changedTouches[0].clientY;

      if (touchStartY.current !== null && touchEndY.current !== null) {
        const touchDiff = touchStartY.current - touchEndY.current;
        const minSwipeDistance = 10;

        if (Math.abs(touchDiff) > minSwipeDistance) {
          const isScrollDown = touchDiff > 0;
          handleScrollAction(isScrollDown);
        }

        touchStartY.current = null;
        touchEndY.current = null;
      }
    },
    [handleScrollAction],
  );

  const handleTouchMove = useCallback((e: TouchEvent) => {
    e.preventDefault(); //모바일 기기 touchMove에 따라 스크롤 움직이지 않도록
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    /**
     * PC 기기에서는 touch 이벤트가 감지 되지 않고,
     * 모바일 기기에서는 wheel 이벤트가 감지되지 않아
     * 각각의 handler로 진행
     */
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
      container.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });
      container.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
      container.addEventListener('touchend', handleTouchEnd, {
        passive: false,
      });
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [handleWheel, handleTouchStart, handleTouchMove, handleTouchEnd]);

  return {
    containerRef,
    currentState,
    scrollDownToSecondDiv: () => {
      setFirstLine();
      scrollToNthFullPageDiv(D.SECOND_DIV);
    },
  };
}
