import styled from '@emotion/styled';
import { largeTitle } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: relative;
  height: 100svh;
  overflow: hidden;
  ${DESKTOP} {
    height: 100vh;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;
  position: relative;
  height: 300px;

  ${DESKTOP} {
    height: 210px;
    width: 775px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  ${largeTitle};
  color: ${vars.text.primary};
  z-index: 10;
  ${DESKTOP} {
    height: 110px;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
  }
`;

export const Video = styled.video`
  width: 191px;
  height: 89px;
  border-radius: 12px;
  overflow: hidden;
  z-index: -1;
  ${DESKTOP} {
    position: absolute;
    width: 256px;
    height: 120px;
  }
`;

export const FirstVideo = styled(Video)`
  top: 0;
  ${DESKTOP} {
    top: -15px;
    right: 130px;
  }
`;

export const SecondVideo = styled(Video)`
  ${DESKTOP} {
    bottom: -18px;
    left: -22px;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  bottom: 25.8px;
  ${DESKTOP} {
    bottom: 67px;
  }
`;
