import { When } from '@teamsparta/react';
import { StackThemeProvider } from '@teamsparta/stack-core';
import { useCustomRemoteConfig } from '@/lib/utils/businessLogics/landing';

import type { CourseCardProps } from './components/CourseCard/template';
import CourseCard from './components/CourseCard/template';
import * as S from './style';

export default function AllCourses() {
  const courseCardData = useCustomRemoteConfig<CourseCardProps[]>(
    'hh_hub_program_card',
  );
  return (
    <When condition={!!courseCardData}>
      <StackThemeProvider theme='hanghaeLight'>
        <S.Container id='hh-program'>
          <S.Titles>
            <S.SubTitle>전체 프로그램</S.SubTitle>
            <S.Title>
              현직 개발자 동료들과 성장하는
              <br />
              프로그램에 지금 합류하세요
            </S.Title>
            <S.Description>
              함께라면, 무엇이든 결국 해낼 수 있습니다.
            </S.Description>
          </S.Titles>
          <S.Cards>
            {courseCardData?.map((course) => (
              <CourseCard key={course.course} {...course} />
            ))}
          </S.Cards>
        </S.Container>
      </StackThemeProvider>
    </When>
  );
}
