import dynamic from 'next/dynamic';
import type { LottieProps } from 'react-lottie';

import { useIsDesktop } from '@/hooks/common/useIsDesktop';
import { useLottie } from '@/hooks/common/useLottie';
import { createCDNUrl } from '@/lib/utils/url';

import { useGetLineEffect } from './logic';
import * as S from './style';

const Lottie = dynamic(
  () =>
    import('react-lottie').then(
      (module) => module.default as React.ComponentType<LottieProps>,
    ),
  {
    ssr: false,
  },
);

export default function LookingForDev({
  currentState,
}: {
  currentState: number;
}) {
  const isDesktop = useIsDesktop();
  const { data: arrowLottie } = useLottie({
    src: createCDNUrl('/hanghae99/landing/hub/rolling_keywords.json'),
    enabled: true,
  });
  const getEffect = useGetLineEffect(currentState);

  return (
    <S.Container>
      <S.Text {...getEffect(0)}>
        포기를 모르는 <strong>동료들</strong>에게 자극 받으며
      </S.Text>
      <S.Text {...getEffect(1)}>주어진 환경의 한계를 돌파할</S.Text>
      <S.Text {...getEffect(2)}>
        <S.Row>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: arrowLottie ?? null,
            }}
            style={{
              width: isDesktop ? '212px' : '123px',
              height: isDesktop ? '90px' : '54px',
            }}
          />
          <span>를 찾습니다</span>
        </S.Row>
      </S.Text>
    </S.Container>
  );
}
