import { useBoolean, usePreserveCallback } from '@teamsparta/react';
import { toast } from '@teamsparta/stack-toast';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { useOnlineUser } from '@/hooks';
import {
  useGetIsMarketingAgreedQuery,
  usePostMarketingAgreement,
} from '@/hooks/react-query/common/users';
import { useLoginModal } from '@/store/login';

/**
 * 이벤트 알림 기능을 관리하는 훅
 *
 * @returns {Object} 이벤트 알림 관련 상태 및 핸들러
 * @returns {Function} requestEventAlerts - 이벤트 알림 신청 프로세스 시작
 * @returns {Function} confirmMarketingAgreement - 마케팅 수신 동의 확인
 * @returns {boolean} isMarketingModalOpen - 마케팅 동의 모달 표시 여부
 * @returns {Function} openMarketingModal - 마케팅 동의 모달 열기
 * @returns {Function} closeMarketingModal - 마케팅 동의 모달 닫기
 * @returns {boolean} isSubmittingAgreement - 마케팅 동의 제출 중 여부
 */
export function useEventNotification() {
  const onlineUser = useOnlineUser();
  const loginModal = useLoginModal();
  const {
    value: isMarketingModalOpen,
    setTrue: openMarketingModal,
    setFalse: closeMarketingModal,
  } = useBoolean(false);

  const { data: isMarketingAgreed } = useGetIsMarketingAgreedQuery(
    onlineUser?._id ?? '',
  );
  const { mutate: submitMarketingAgreement, isPending: isSubmittingAgreement } =
    usePostMarketingAgreement(onlineUser?._id ?? '');

  /**
   * 이벤트 알림 신청 프로세스를 시작하는 함수
   * 1. 로그인 여부 확인
   * 2. 마케팅 수신 동의 여부 확인
   * 3. 필요 시 마케팅 동의 모달 표시
   */
  const requestEventAlerts = useCallback(() => {
    if (!onlineUser) {
      loginModal.open(`/?${NOTIFICATION_QUERY_KEY}=true`);
      return;
    }

    if (isMarketingAgreed === undefined) {
      return;
    }

    if (isMarketingAgreed) {
      toast.success('알림 신청이 완료되었습니다.');
      return;
    }

    openMarketingModal();
  }, [isMarketingAgreed, loginModal, onlineUser, openMarketingModal]);

  /**
   * 마케팅 수신 동의 확인 시 실행되는 함수
   * 마케팅 동의 정보를 서버에 제출하고 결과에 따라 UI 업데이트
   */
  const confirmMarketingAgreement = useCallback(() => {
    submitMarketingAgreement(undefined, {
      onSuccess: () => {
        toast.success('알림 신청이 완료되었습니다.');
      },
      onError: () => {
        alert('다시 시도해 주세요.\n문제가 반복된다면 문의를 남겨주세요.');
      },
      onSettled: () => {
        closeMarketingModal();
      },
    });
  }, [closeMarketingModal, submitMarketingAgreement]);

  return {
    requestEventAlerts,
    confirmMarketingAgreement,
    isMarketingModalOpen,
    openMarketingModal,
    closeMarketingModal,
    isSubmittingAgreement,
  };
}

/** 이벤트 알림 요청에 사용되는 쿼리 파라미터 키 */
const NOTIFICATION_QUERY_KEY = 'event-notification';
/** 알림 컨테이너로 스크롤하기 전 대기 시간 (ms) */
const SCROLL_DELAY = 500;
/** 알림 요청 실행 전 대기 시간 (ms) */
const SUBSCRIBE_DELAY = 1500;
/**
 * URL 쿼리 파라미터를 기반으로 이벤트 알림 컨테이너로 스크롤하고 알림 요청을 실행하는 훅
 *
 * @param {Object} params - 훅 파라미터
 * @param {Function} params.onNotificationRequest - 알림 요청 실행 함수
 * @returns {Object} 스크롤 대상 컨테이너 참조
 */
export function useEventNotificationScroll({
  onNotificationRequest,
}: {
  onNotificationRequest: () => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const preservedRequestCallback = usePreserveCallback(onNotificationRequest);

  useEffect(() => {
    const hasNotificationQuery = router.query[NOTIFICATION_QUERY_KEY];

    if (!hasNotificationQuery) {
      return;
    }

    /**
     * 알림 컨테이너로 스크롤하고 URL에서 쿼리 파라미터 제거
     */
    const scrollToContainer = () => {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
      router.replace(router.pathname, undefined, { shallow: true });
    };

    setTimeout(scrollToContainer, SCROLL_DELAY);
    setTimeout(preservedRequestCallback, SUBSCRIBE_DELAY);
  }, [router, preservedRequestCallback]);

  return { containerRef };
}
