import { useIsDesktop } from '@/hooks';

const FIRST = {
  START: 0.5,
  DURATION: 0.5,
};
const SECOND = {
  START: FIRST.START + 1,
  DURATION: 0.5,
};
const LINES_APART = {
  START: SECOND.START + 1,
  DURATION: 0.5,
};
const PICTURES_SHOW = {
  START: LINES_APART.START + 0.5,
  DURATION: 1,
};

const ARROW_SHOW = {
  START: PICTURES_SHOW.START + 1,
  DURATION: 0.5,
};

export function useMotionEffect() {
  const isDesktop = useIsDesktop();
  const motionEffect = {
    title: {
      firstLine: {
        initial: { opacity: 0, y: -50, x: 0 },
        animate: { opacity: 1, y: 0, x: isDesktop ? -160 : 0, zIndex: 1 },
        transition: {
          opacity: {
            delay: FIRST.START,
            duration: FIRST.DURATION,
          },
          y: {
            delay: FIRST.START,
            duration: FIRST.DURATION,
          },
          x: {
            delay: LINES_APART.START,
            duration: LINES_APART.DURATION,
            zIndex: 1,
          },
        },
      },
      secondLine: {
        initial: { opacity: 0, x: 0 },
        animate: { opacity: 1, x: isDesktop ? 120 : 0 },
        transition: {
          opacity: {
            delay: SECOND.START,
            duration: SECOND.DURATION,
          },
          x: {
            delay: LINES_APART.START,
            duration: LINES_APART.DURATION,
          },
        },
      },
    },
    pictures: {
      mobile: {
        firstLine: {
          initial: {
            opacity: 0,
            x: 416,
            y: 24,
            zIndex: -1,
          },
          animate: { opacity: 1, x: 116 },
          transition: {
            opacity: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
            x: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
          },
        },
        secondLine: {
          initial: {
            opacity: 0,
            x: -514,
            y: -20,
            zIndex: -1,
          },
          animate: { opacity: 1, x: -114, zIndex: -1 },
          transition: {
            opacity: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
            x: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
              zIndex: -2,
            },
          },
        },
      },
      desktop: {
        firstLine: {
          initial: {
            opacity: 0,
          },
          animate: { opacity: 1 },
          transition: {
            opacity: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
            x: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
          },
        },
        secondLine: {
          initial: {
            opacity: 0,
          },
          animate: { opacity: 1 },
          transition: {
            opacity: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
            x: {
              delay: PICTURES_SHOW.START,
              duration: PICTURES_SHOW.DURATION,
            },
          },
        },
      },
    },
    arrow: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      transition: {
        delay: ARROW_SHOW.START,
        duration: ARROW_SHOW.DURATION,
      },
    },
  };

  return { motionEffect };
}
