import Image from 'next/image';

import { createCDNUrl } from '@/lib/utils/url';

import * as S from './style';

export default function Card({
  title,
  description,
  backgroundColor,
  image,
}: {
  title: string;
  description: string;
  backgroundColor: string;
  image: string;
}) {
  return (
    <S.Card backgroundColor={backgroundColor}>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      <S.Image>
        <Image src={createCDNUrl(image)} alt={title} width={546} height={250} />
      </S.Image>
    </S.Card>
  );
}
