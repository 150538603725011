import { motion } from 'framer-motion';
import Image from 'next/image';

import { useIsDesktop } from '@/hooks/common/useIsDesktop';
import { createCDNUrl } from '@/lib/utils/url';
import Only from '@/components/common/Only';

import logo from '/public/images/logo/logo.png';
import { useGetMotionDetails, useHandleScroll } from './logic';
import * as S from './style';

export default function Crew() {
  const { containerRef, scrollRatio } = useHandleScroll();
  const motionDetails = useGetMotionDetails(scrollRatio);

  return (
    <S.Container ref={containerRef}>
      <S.TextContainer>
        <S.Text>
          <motion.div {...motionDetails.firstLine}>
            혼자서는 넘기 어려운 성장의 벽을
            <br />
          </motion.div>
        </S.Text>
        <S.Text>
          <motion.div {...motionDetails.secondLine}>
            집단 지성의 힘으로 뛰어넘는
            <Only.Mobile>
              <br />
            </Only.Mobile>
            <S.Row>
              학습 크루
              <S.LogoWrapper>
                <Image
                  src={logo}
                  layout='fill'
                  objectFit='contain'
                  alt='logo'
                />
              </S.LogoWrapper>
            </S.Row>
          </motion.div>
        </S.Text>
      </S.TextContainer>
      <S.RollingBackground>
        <Background />
      </S.RollingBackground>
    </S.Container>
  );
}

export function Background() {
  const isDesktop = useIsDesktop();
  return (
    <S.Background>
      <Image
        src={createCDNUrl(
          `/hanghae99/landing/hub/crew_bg_${isDesktop ? 'desktop' : 'mo'}.webp`,
        )}
        width={isDesktop ? 2160 : 375}
        height={isDesktop ? 3285 : 1692}
        alt='crew'
      />
    </S.Background>
  );
}
