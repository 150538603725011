import { useAtom } from 'jotai';
import type { NextApiRequest } from 'next';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import ChannelTalkBtn from '@/components/common/ChannelTalkBtn';
import { Meta } from '@/components/common/Meta';
import { OpenGraph } from '@/components/common/OpenGraph';
import { BeusableScript } from '@/lib/sdk/beusable';
import { sendHubPageViewLog } from '@/lib/utils/businessLogics/logs/landing';
import { processUrl } from '@/lib/utils/businessLogics/utils/_helper';
import { getOnlineUserIdFromServerCookie } from '@/lib/utils/cookie';
import { env } from '@/lib/utils/env';
import HubPageTemplate from '@/pages/hub/template';
import { isLoginModalShowAtom, nextPageAfterLoginAtom } from '@/store/login';

export default function Home({
  onlineUserId,
}: {
  onlineUserId: string;
  resolvedUrl: string;
}) {
  const router = useRouter();
  const [, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [, setNextPageAfterLogin] = useAtom(nextPageAfterLoginAtom);
  const searchParams = useSearchParams();
  const nextPage = searchParams?.get('next');

  useEffect(() => {
    if (nextPage && !onlineUserId) {
      setIsLoginModalShow(true);
      setNextPageAfterLogin(nextPage);
      router.push('/');
    }
  }, [
    nextPage,
    onlineUserId,
    router,
    setIsLoginModalShow,
    setNextPageAfterLogin,
  ]);

  useEffect(() => {
    sendHubPageViewLog();
  }, []);

  return (
    <>
      <Meta
        title='항해99 - 성장의 벽을 동료들과 함께 뛰어넘는 개발자 학습 크루'
        description='성장에 대한 열망이 검증된 2000명 이상의 현직 개발자, 탑티어 시니어 코치진, 학습 후에도 이어지는 개발자 커뮤니티까지. 항해99에서 끊임없이 성장하는 개발자 크루에 합류하세요.'
        url={`${env.NEXT_PUBLIC_PAGE_URL}`}
        canonical={`${env.NEXT_PUBLIC_PAGE_URL}`}
      />
      <OpenGraph
        title='개발자 커리어 개척 캠프 항해99, 첫 취업부터 현직자 역량 강화까지'
        description='10년이 지나도 남는 커리큘럼을 바탕으로 커리어를 개척하세요. 진정성있는 멘토링과 2천 명이 넘는 끈끈한 커뮤니티가 여러분과 함께 합니다.'
        url={`${env.NEXT_PUBLIC_PAGE_URL}`}
        image='/images/main/og.png'
        imageType='image/png'
        imageHeight={630}
        imageWidth={1200}
        siteName='항해99'
        type='website'
        locale='ko_KR'
      />
      <HubPageTemplate />
      <ChannelTalkBtn bottom={15} />
      <BeusableScript />
    </>
  );
}

export async function getServerSideProps({
  req,
  resolvedUrl,
}: {
  req: NextApiRequest;
  resolvedUrl: string;
}) {
  const processed = processUrl(req);
  if (processed) {
    return processed;
  }
  const onlineUserId = getOnlineUserIdFromServerCookie(req);
  return {
    props: {
      onlineUserId,
      resolvedUrl,
    },
  };
}
