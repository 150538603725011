import Head from 'next/head';

interface OpenGraphProps {
  /**
   * 페이지 제목
   */
  title: string;
  /**
   * 페이지 설명
   */
  description: string;
  /**
   * 페이지 이미지
   */
  image: string;
  /**
   * 페이지 URL
   */
  url: string;
  /**
   * 페이지 타입
   * @default 'website'
   */
  type?: 'website' | 'article';
  /**
   * 페이지 언어
   * @default 'ko_KR'
   */
  locale?: 'ko_KR';
  /**
   * 이미지 너비
   * @default 1200
   */
  imageWidth?: number;
  /**
   * 이미지 높이
   * @default 630
   */
  imageHeight?: number;
  /**
   * 이미지 타입
   * @default 'image/png'
   * @example 'image/png' | 'image/jpeg' | 'image/webp'
   */
  imageType?: 'image/png' | 'image/jpeg' | 'image/webp';
  /**
   * 이미지 대체 텍스트
   * @default title
   */
  imageAlt?: string;
  /**
   * 사이트 이름
   * @default '항해99'
   */
  siteName?: string;
}

export function OpenGraph({
  title,
  description,
  image,
  url,
  type = 'website',
  locale = 'ko_KR',
  imageWidth = 1200,
  imageHeight = 630,
  imageType = 'image/png',
  imageAlt = title,
  siteName = '항해99',
}: OpenGraphProps) {
  return (
    <Head>
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:url' content={url} />
      <meta property='og:type' content={type} />
      <meta property='og:site_name' content={siteName} />
      <meta property='og:locale' content={locale} />
      <meta property='og:image:width' content={imageWidth.toString()} />
      <meta property='og:image:height' content={imageHeight.toString()} />
      <meta property='og:image:type' content={imageType} />
      <meta property='og:image:alt' content={imageAlt} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
    </Head>
  );
}
