import type { CourseCardProps } from './template';

function isRealObject(item: any): boolean {
  return typeof item === 'object' && item !== null;
}

/**
 * 원격구성 hh_hub_program_card 값이 카드를 렌더링 하기에 적합한 값인지 검증
 * @param course
 */
export function isValidCourse(remoteValue: CourseCardProps): boolean {
  if (!remoteValue) {
    return false;
  }

  const { course, badge, traits, title, description, justAlert, cta } =
    remoteValue;

  // course 값이 'plus', 'skillup', 'study', 'club99' 중 하나인지 검증
  const VALID_REMOTE_COURSES = ['plus', 'skillup', 'study', 'club99'];
  if (!course || !VALID_REMOTE_COURSES.includes(course)) {
    return false;
  }

  // badge 값이 배열이고, 배열의 요소가 객체이고, 객체의 키가 text와 color인지 검증
  if (
    !Array.isArray(badge) ||
    !badge.every((item) => isRealObject(item) && item.text && item.color)
  ) {
    return false;
  }

  // traits 값이 배열이고, traits의 요소가 객체이고, 객체의 키가 text와 icon인지 검증
  // icon 값이 'calendar', 'person', 'note', 'review', 'code', 'video', 'certi' 중 하나인지 검증
  const VALID_REMOTE_ICONS = [
    'calendar',
    'person',
    'note',
    'review',
    'code',
    'video',
    'certi',
  ];
  if (
    !Array.isArray(traits) ||
    !traits.every(
      (item) =>
        isRealObject(item) &&
        item.text &&
        item.icon &&
        VALID_REMOTE_ICONS.includes(item.icon as string),
    )
  ) {
    return false;
  }

  // title, description, justAlert 값이 존재하는지 검증
  if (!title || !description || !justAlert) {
    return false;
  }

  // cta 값이 객체이고, 객체의 키값이 존재하는지 검증
  if (!isRealObject(cta) || !cta.label || !cta.url) {
    return false;
  }

  // 모든 검증을 통과했을 경우
  return true;
}
