import Footer from '@/components/common/Footer';
import Gnb from '@/components/gnb/GNB/Gnb';

import * as C from './components';
import * as L from './logic';
import * as S from './style';

export default function HubPageTemplate() {
  /**
   * 위아래 스크롤할때 첫 두 div는 섹션 단위로만 스크롤되게 하는 로직 관련
   */
  const { containerRef, currentState, scrollDownToSecondDiv } =
    L.useHandleHubFullPageScroll();

  return (
    <S.Container>
      <C.FlameBackGround />
      <Gnb />
      <div ref={containerRef}>
        <C.HubHero scrollDownToSecondDiv={scrollDownToSecondDiv} />
        <C.LookingForDev currentState={currentState} />
      </div>
      <C.Crew />
      <C.MultipleSkill />
      <C.Companion />
      <C.GrowthReview />
      <C.AllCourses />
      <C.Event />
      <Footer />
    </S.Container>
  );
}
