import { bodyM, captionM, subTitle2, title1 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';
import { DESKTOP } from '@/styles/themes';
import styled from '@emotion/styled';

export const Card = styled.div<{ backgroundColor: string }>`
  width: 343px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-top: 20px;
  border-radius: 16px;
  overflow: hidden;
  height: fit-content;

  ${DESKTOP} {
    padding-top: 32px;
    width: 546px;
  }
`;

export const Title = styled.div`
  color: ${vars.neutral[10]};
  ${subTitle2};
  width: 303px;
  margin: 0 auto 10px auto;
  white-space: pre-wrap;
  ${DESKTOP} {
    margin-bottom: 16px;
    width: 482px;
    ${title1};
  }
`;

export const Description = styled.div`
  color: ${vars.text.tertiary};
  word-break: keep-all;
  ${captionM};
  width: 303px;
  margin: 0 auto;
  ${DESKTOP} {
    width: 482px;
    ${bodyM};
  }
`;

export const Image = styled.div`
  transform: translateY(3px);

  img {
    width: 343px;
    height: 157px;
  }

  ${DESKTOP} {
    img {
      width: 546px;
      height: 250px;
    }
  }
`;
