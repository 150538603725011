import { ArrowRight } from '@teamsparta/stack-icons';
import { vars } from '@teamsparta/stack-tokens';
import Image from 'next/image';

import { createCDNUrl } from '@/lib/utils/url';

import * as D from './data';
import * as S from './style';

export default function MultipleSkill() {
  return (
    <S.Container>
      <S.Title>
        혼자 듣는 온라인 강의만으론
        <br />
        <S.HighlightText>좋은 개발자</S.HighlightText>가 되기 어렵습니다.
      </S.Title>
      <S.Content>
        <S.ContentItemImage>
          <Image
            src={createCDNUrl('/hanghae99/landing/hub/skillgraph.webp')}
            alt='skillgraph'
            layout='fill'
            objectFit='contain'
          />
        </S.ContentItemImage>
        <S.ContentItem>
          <S.Paragraph>
            실무에서 빛을 발하려면 다양한 역량을 키워야합니다.
          </S.Paragraph>
          <S.Paragraph>
            하지만 강의나 독학만으로는 한계가 있습니다.
            <S.LimitationItem>
              {D.learningLimitations.map((item) => (
                <S.LimitationItemWrapper key={item.limitation}>
                  <S.LimitationText>{item.limitation}</S.LimitationText>
                  <ArrowRight size={16} color={vars.text.secondary} />
                  <S.ConsequenceText>{item.consequence}</S.ConsequenceText>
                </S.LimitationItemWrapper>
              ))}
            </S.LimitationItem>
          </S.Paragraph>
          <S.Paragraph>
            <strong>그래서 우리는 함께 학습합니다.</strong>
          </S.Paragraph>
          <S.Paragraph>
            동기들과 협업하고 시니어에게 피드백 받으며
            <br />
            혼자일 때보다 더 넓고 깊게 성장하는 것이 우리의 목표입니다.
          </S.Paragraph>
        </S.ContentItem>
      </S.Content>
    </S.Container>
  );
}
