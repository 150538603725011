import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getIsMarketingAgreed,
  postMarketingAgreement,
} from '@/api/common/users/user';

const onlineUserQueryKeys = {
  all: ['onlineUserId'],
  isMarketingAgreed: (onlineUserId: string) => [
    ...onlineUserQueryKeys.all,
    onlineUserId,
  ],
};

export const useGetIsMarketingAgreedQuery = (onlineUserId: string) =>
  useQuery({
    queryKey: onlineUserQueryKeys.isMarketingAgreed(onlineUserId),
    queryFn: () => getIsMarketingAgreed(onlineUserId),
    enabled: Boolean(onlineUserId),
  });

export const usePostMarketingAgreement = (onlineUserId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postMarketingAgreement,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: onlineUserQueryKeys.isMarketingAgreed(onlineUserId),
      });
    },
  });
};
