import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useIsDesktop } from '@/hooks/common/useIsDesktop';

export function useGetMotionDetails(scrollRatio: number) {
  const isDesktop = useIsDesktop();
  const TRANSFORM_Y = isDesktop ? -60 : -40;

  const TRANSITION = { duration: 1, ease: 'easeInOut' };
  const isPastTrigger = scrollRatio >= 0.4;

  return {
    firstLine: {
      initial: { opacity: 1, y: 50, scale: 1 },
      animate: {
        opacity: isPastTrigger ? 0.4 : 1,
        y: isPastTrigger ? TRANSFORM_Y : 0,
        scale: isPastTrigger ? 0.8 : 1,
      },
      transition: TRANSITION,
    },
    secondLine: {
      initial: { opacity: 0 },
      animate: {
        opacity: isPastTrigger ? 1 : 0,
      },
      transition: TRANSITION,
    },
  };
}

export function useHandleScroll() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollRatio, setScrollRatio] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const windowHeight = window.innerHeight;
    const handleScroll = () => {
      if (container) {
        const { top: containerTop } = container.getBoundingClientRect();
        //스크롤 비율 계산 (텍스트 움직임 효과)
        const calcualtedRatio =
          (windowHeight - containerTop) /
          (windowHeight + container.offsetHeight);
        setScrollRatio(Math.max(0, Math.min(1, calcualtedRatio)));
      }
    };

    const throttledHandleScroll = _.throttle(handleScroll, 15);
    window.addEventListener('scroll', throttledHandleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, []);

  return { containerRef, scrollRatio };
}
