import { instanceV2, instanceWithAuth } from '@/api/instance';

import type {
  GetIsMarketingAgreedResponseDto,
  PostMarketingAgreementRequestDto,
} from './users.dto';

//유저가 마케팅 수신 여부에 동의했는지 조회
export async function getIsMarketingAgreed(onlineUserId: string) {
  return await instanceV2.get<GetIsMarketingAgreedResponseDto>(
    `/v3/online-users/marketing/${onlineUserId}`,
  );
}

//유저의 마케팅 수신 동의 POST
export async function postMarketingAgreement() {
  return await instanceWithAuth.post<PostMarketingAgreementRequestDto>(
    `/v3/online-users/marketing`,
  );
}
