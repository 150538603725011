import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';

export const Overlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: ${vars.dimm.default};
`;

export const Content = styled(motion.div)`
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 32px;
  background: ${vars.background.default};
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${vars.fill.subtle};
  padding: 6px;
  border-radius: 999px;
`;

export const CheckboxContainer = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${vars.neutral[40]};

  &[data-state='checked'] {
    background-color: ${vars.brand.default};
    border: 1px solid ${vars.brand.default};
  }
`;

export const overlayVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const mobileVariants = {
  initial: {
    opacity: 0,
    y: '100%',
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '100%',
    borderRadius: '16px 16px 0 0',
  },
  animate: {
    opacity: 1,
    y: 0,
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '100%',
  },
  exit: {
    opacity: 0,
    y: '100%',
  },
} satisfies Variants;

export const desktopVariants = {
  initial: {
    opacity: 0,
    y: -20,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxWidth: '460px',
    borderRadius: '16px',
  },
  animate: {
    opacity: 1,
    y: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxWidth: '460px',
  },
  exit: {
    opacity: 0,
    y: -20,
  },
} satisfies Variants;
