import Image from 'next/image';
import { useRef } from 'react';

import { useIsDesktop } from '@/hooks';

import * as S from './style';

interface InfiniteImageScrollProps {
  images: string[];
  speed?: number;
}

interface ImageSetProps {
  images: string[];
  prefix: string;
  width: number;
  height: number;
}

const ImageSet = ({ images, prefix, width, height }: ImageSetProps) => {
  return (
    <>
      {images.map((src, index) => (
        <S.ImageWrapper key={`${prefix}-${index}`}>
          <Image
            src={src}
            alt={`이벤트 이미지 ${index + 1}`}
            width={width}
            height={height}
            style={{ objectFit: 'cover' }}
          />
        </S.ImageWrapper>
      ))}
    </>
  );
};

const InfiniteImageScroll = ({
  speed = 30, // 기본 속도를 더 빠르게 설정 (작은 값 = 빠른 속도)
  images,
}: InfiniteImageScrollProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const isDesktop = useIsDesktop();
  // 이미지 너비 및 간격 상수 정의
  const IMAGE_WIDTH = isDesktop ? 400 : 267; // 이미지 너비 (px)
  const IMAGE_HEIGHT = isDesktop ? 209 : 140; // 이미지 높이 (px)
  const IMAGE_GAP = isDesktop ? 24 : 16; // 이미지 사이 간격 (px)

  // 컨테이너 너비 계산: (이미지 너비 × 이미지 개수) + (간격 × (이미지 개수 - 1))
  const containerWidth =
    IMAGE_WIDTH * images.length + IMAGE_GAP * (images.length - 1);

  return (
    <S.Container>
      <S.ScrollContainer ref={scrollRef}>
        <S.ScrollContent speed={speed} containerWidth={containerWidth}>
          {/* 첫 번째 이미지 세트 */}
          <div
            className='image-set-1'
            style={{ display: 'flex', gap: `${IMAGE_GAP}px` }}
          >
            <ImageSet
              images={images}
              prefix='img1'
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
            />
          </div>

          {/* 두 번째 이미지 세트 (똑같은 내용 반복) */}
          <div
            className='image-set-2'
            style={{ display: 'flex', gap: `${IMAGE_GAP}px` }}
          >
            <ImageSet
              images={images}
              prefix='img2'
              width={IMAGE_WIDTH}
              height={IMAGE_HEIGHT}
            />
          </div>
        </S.ScrollContent>
      </S.ScrollContainer>
    </S.Container>
  );
};

export default InfiniteImageScroll;
