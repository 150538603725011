import { Button } from '@teamsparta/stack-button';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { LoggingClick } from '@/components/common/LoggingClick';
import { createCDNUrl } from '@/lib/utils/url';
import Only from '@/components/common/Only';

import InfiniteImageScroll from './components/InfiniteImageScroll';
import { MarketingAgreementModal } from './components/MarketingAgreementModal';
import { useEventNotification, useEventNotificationScroll } from './logic';
import * as S from './style';

export default function Event() {
  const {
    isMarketingModalOpen,
    requestEventAlerts,
    confirmMarketingAgreement,
    isSubmittingAgreement,
    closeMarketingModal,
  } = useEventNotification();
  const { containerRef } = useEventNotificationScroll({
    onNotificationRequest: requestEventAlerts,
  });

  return (
    <>
      <S.Container>
        <S.TitleWrapper>
          <Text
            as='h2'
            font={{
              mobile: 'title2',
              desktop: 'largeTitle',
            }}
            color={vars.neutral[10]}
          >
            프로그램 외에도 <br />
            다양하게 열리는 개발자 이벤트
          </Text>
        </S.TitleWrapper>

        <InfiniteImageScroll
          speed={20}
          images={[
            createCDNUrl('/hanghae99/landing/hub/event_jandi.webp'),
            createCDNUrl('/hanghae99/landing/hub/event_hunter.webp'),
            createCDNUrl('/hanghae99/landing/hub/event_calendar.webp'),
            createCDNUrl('/hanghae99/landing/hub/event_club.webp'),
          ]}
        />

        <S.Banner ref={containerRef}>
          <S.Message>
            곧 오픈하는 이벤트 소식,{' '}
            <Only.Mobile>
              <br />
            </Only.Mobile>
            가장 먼저 받아보고 싶다면?
          </S.Message>
          <LoggingClick
            logName='hh_hub_EventButton_click'
            data={{
              button_title: '알림 신청하기',
            }}
          >
            <Button
              size={{ desktop: 'md', mobile: 'sm' }}
              colorScheme='secondary'
              onClick={requestEventAlerts}
            >
              알림 신청하기
            </Button>
          </LoggingClick>
        </S.Banner>
      </S.Container>

      <MarketingAgreementModal
        opened={isMarketingModalOpen}
        isMutating={isSubmittingAgreement}
        onConfirm={confirmMarketingAgreement}
        onClose={closeMarketingModal}
      />
    </>
  );
}
