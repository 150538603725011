import { bodyB, largeTitle } from '@teamsparta/stack-font';
import { bodyCompact, bodyM, title2 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';
import { DESKTOP } from '@/styles/themes';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 16px;
  gap: 40px;
  background: ${vars.dimm.default};
  backdrop-filter: blur(6px);
  ${DESKTOP} {
    gap: 60px;
    padding: 100px 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  align-self: stretch;

  ${DESKTOP} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 40px;
  gap: 10px;

  ${DESKTOP} {
    padding-top: 0px;
    gap: 20px;
  }
`;

export const Paragraph = styled.div`
  color: ${vars.text.secondary};
  text-align: left;
  width: 100%;

  ${bodyCompact};

  ${DESKTOP} {
    ${bodyM};
    strong {
      ${bodyB};
    }
  }

  white-space: pre-line;
`;
export const LimitationItem = styled.div`
  text-align: left;
  width: 100%;

  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  border-left: 3px solid ${vars.text.secondary};
  margin-top: 10px;
  ${DESKTOP} {
    margin-top: 12px;
  }
`;

export const ContentItemImage = styled.div`
  position: relative;
  width: 343px;
  height: 235px;
  flex-shrink: 0;
  ${DESKTOP} {
    width: 480px;
    height: 330px;
  }
`;

export const Title = styled.div`
  color: ${vars.text.primary};
  text-align: center;

  ${title2};

  white-space: pre-line;

  ${DESKTOP} {
    ${largeTitle};
  }
`;

export const HighlightText = styled.span`
  color: ${vars.brand.default};

  ${title2};

  ${DESKTOP} {
    ${largeTitle};
  }
`;

export const LimitationText = styled.div`
  color: ${vars.text.secondary};

  ${bodyCompact};

  ${DESKTOP} {
    ${bodyM};
  }
`;

export const ConsequenceText = styled.div`
  color: ${vars.brand.strong};

  ${bodyCompact};

  ${DESKTOP} {
    ${bodyM};
  }
`;

export const Description = styled.div``;

export const DescriptionBold = styled.div`
  color: ${vars.text.primary};
  text-align: left;
  width: 100%;

  ${bodyCompact};

  ${DESKTOP} {
    ${bodyB};
  }
`;

export const LimitationItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
`;
