import Image from 'next/image';
import { DoubleQuotation } from 'public/svg/pm/payment/DoubleQuotation';

import { ArrowChevronRight } from '@teamsparta/stack-icons';
import { vars } from '@teamsparta/stack-tokens';
import { LoggingClick } from '@/components/common/LoggingClick';

import * as D from './data';
import * as S from './style';

export default function GrowthReview() {
  const handleCardClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <S.Container>
      <S.Title>
        연봉 상승부터 대기업 이직까지
        <br />
        동료들의 성장 후기
      </S.Title>
      <S.CardContainer>
        {D.plusGrowthReviews.map((item) => (
          <S.Card key={item.title}>
            <DoubleQuotation
              width={15}
              height={12}
              color={vars.brand.default}
            />
            <S.CardTitle>{item.title}</S.CardTitle>
            <S.CardDescription>{item.comments}</S.CardDescription>
            <LoggingClick
              logName='hh_hub_ReviewCard_click'
              data={{
                button_title: item.logContent,
              }}
            >
              <S.CardButton onClick={() => handleCardClick(item.link)}>
                <Image
                  src={item.image}
                  alt={item.course}
                  width={72}
                  height={44}
                />
                <S.CardButtonTitleWrapper>
                  <S.CourseName>{item.course}</S.CourseName>
                  <S.CardButtonTitle>{item.buttonTitle}</S.CardButtonTitle>
                </S.CardButtonTitleWrapper>
                <ArrowChevronRight size={20} color={vars.icon.quaternary} />
              </S.CardButton>
            </LoggingClick>
          </S.Card>
        ))}
      </S.CardContainer>
    </S.Container>
  );
}
