import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import type { LottieProps } from 'react-lottie';

import Only from '@/components/common/Only';
import { useIsDesktop, useLottie } from '@/hooks';
import { createCDNUrl } from '@/lib/utils/url';

import { useMotionEffect } from './logic';
import * as S from './style';

const Lottie = dynamic(
  () =>
    import('react-lottie').then(
      (module) => module.default as React.ComponentType<LottieProps>,
    ),
  {
    ssr: false,
  },
);

export default function HubHeroTemplate({
  scrollDownToSecondDiv,
}: {
  scrollDownToSecondDiv: () => void;
}) {
  const { motionEffect } = useMotionEffect();

  const { data: arrowLottie } = useLottie({
    src: createCDNUrl('/hanghae99/landing/hub/scrolldown_arrow.json'),
    enabled: true,
  });
  const isDesktop = useIsDesktop();

  return (
    <S.Container>
      <S.Content>
        <Only.Mobile>
          <motion.div {...motionEffect.pictures.mobile.firstLine}>
            <S.FirstVideo
              src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/hanghae99/landing/hub/first_line.mp4`}
              autoPlay
              muted
              loop
              playsInline
              controls={false}
            />
          </motion.div>
        </Only.Mobile>
        <Only.Desktop>
          <motion.div {...motionEffect.pictures.desktop.firstLine}>
            <S.FirstVideo
              src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/hanghae99/landing/hub/first_line.mp4`}
              autoPlay
              muted
              loop
              playsInline
              controls={false}
            />
          </motion.div>
        </Only.Desktop>
        <motion.div {...motionEffect.title.firstLine}>
          <S.Title>결국 해내는</S.Title>
        </motion.div>
        <motion.div {...motionEffect.title.secondLine}>
          <S.Title>개발자들의 성장 크루</S.Title>
        </motion.div>
        <Only.Mobile>
          <motion.div {...motionEffect.pictures.mobile.secondLine}>
            <S.SecondVideo
              src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/hanghae99/landing/hub/second_line.mp4`}
              autoPlay
              muted
              loop
              playsInline
              controls={false}
            />
          </motion.div>
        </Only.Mobile>
        <Only.Desktop>
          <motion.div {...motionEffect.pictures.desktop.secondLine}>
            <S.SecondVideo
              src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/hanghae99/landing/hub/second_line.mp4`}
              autoPlay
              muted
              loop
              playsInline
              controls={false}
            />
          </motion.div>
        </Only.Desktop>
      </S.Content>

      <S.Arrow onClick={scrollDownToSecondDiv}>
        <motion.div {...motionEffect.arrow}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: arrowLottie ?? null,
            }}
            style={{
              width: isDesktop ? '41px' : '32px',
              height: isDesktop ? '41px' : '32px',
              cursor: 'pointer',
            }}
          />
        </motion.div>
      </S.Arrow>
    </S.Container>
  );
}
