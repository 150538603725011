import {
  ArrowRight,
  ContentsNote,
  InfoCalendar,
} from '@teamsparta/design-system';
import {
  ContentsCode,
  ContentsOnlineInterview,
  ContentsPlayCircle,
  InfoVerified,
  NavigationMyPage,
} from '@teamsparta/stack-icons';
import Image from 'next/image';

import { Button } from '@teamsparta/stack-button';
import { vars } from '@teamsparta/stack-tokens';
import { LoggingClick } from '@/components/common/LoggingClick';
import { useIsDesktop } from '@/hooks/common/useIsDesktop';
import { createCDNUrl } from '@/lib/utils/url';
import { When } from '@teamsparta/react';
import type { LineFillIcon } from '@teamsparta/stack-icons';

import { isValidCourse } from './logic';
import * as S from './style';

export type CourseCardProps = {
  course: string;
  badge: { text: string; color: string }[];
  title: string;
  description: string;
  justAlert: string;
  traits: { text: string; icon: string }[];
  cta: { label: string; url: string };
};
export default function CourseCard(props: CourseCardProps) {
  const { course, badge, title, description, traits, cta } = props;
  const isDesktop = useIsDesktop();

  return (
    <When condition={isValidCourse(props)}>
      <S.Card>
        <S.BackgroundImage>
          <Image
            src={createCDNUrl(
              `/hanghae99/landing/hub/course_${
                isDesktop ? 'pc' : 'mo'
              }_${course}.webp`,
            )}
            alt={`${course} 배경 이미지`}
            width={isDesktop ? 270 : 255}
            height={isDesktop ? 210 : 210}
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </S.BackgroundImage>
        <S.Badges>
          {badge?.map(({ text, color }) => (
            <S.Badge key={text} color={color}>
              {text}
            </S.Badge>
          ))}
        </S.Badges>
        <S.Content>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <S.Traits>
            {traits?.map(({ text, icon }) => (
              <S.Trait key={text}>
                <GetIcon icon={icon} />
                <span>{text}</span>
              </S.Trait>
            ))}
          </S.Traits>

          <LoggingClick
            logName='hh_hub_CourseCard_click'
            data={{
              card_title: cta.label,
            }}
          >
            <Button
              fullWidth
              rightAddon={<ArrowRight size={16} color={vars.neutral[10]} />}
              onClick={() => {
                window.open(cta.url, '_blank');
              }}
            >
              {cta.label} 알아보기
            </Button>
          </LoggingClick>
        </S.Content>
      </S.Card>
    </When>
  );
}

function GetIcon({ icon }: { icon: string }) {
  const props: LineFillIcon = {
    size: 20,
    color: vars.neutral[30],
    style: 'fill',
  };

  switch (icon) {
    case 'calendar':
      return <InfoCalendar {...props} />;
    case 'person':
      return <NavigationMyPage {...props} />;
    case 'note':
      return <ContentsNote {...props} />;
    case 'review':
      return <ContentsOnlineInterview {...props} />;
    case 'code':
      return <ContentsCode {...props} />;
    case 'video':
      return <ContentsPlayCircle {...props} />;
    case 'certi':
      return <InfoVerified {...props} />;
    default:
      return <InfoCalendar {...props} />;
  }
}
