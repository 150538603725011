import { createCDNUrl } from '@/lib/utils/url';

export type GrowthReviews = {
  title: string;
  comments: React.ReactNode;
  course: string;
  buttonTitle: string;
  logContent: string;
  image: string;
  link: string;
};
export const plusGrowthReviews: GrowthReviews[] = [
  {
    title: '초봉 대비 50% 높은 연봉을\n제안 받았어요.',
    comments: (
      <>
        동료들과 함께하는 실무 역량 강화 프로그램을 수료한 직후,{' '}
        <strong>이직에 성공</strong>해서 직전 연봉 대비 20% 인상되었어요. 초봉에
        비하면 50% 오른 수준입니다. 현업의 반복되는 레퍼토리와 개발 문화에
        동화되어 있었는데, 처음으로 회사 밖 다른 개발자와 협업해보면서{' '}
        <strong>마음 맞는 동료</strong>도 만나게 되어 좋았습니다.
      </>
    ),
    course: '항해 플러스 백엔드 코스',
    logContent: '카드 1 (금교석 님)',
    buttonTitle: '1기 금교석님 인터뷰 읽어보기',
    image: createCDNUrl('/hanghae99/landing/hub/interview_1.webp'),
    link: 'https://hanghae99.spartacodingclub.kr/blog/항해-플러스-후기-이직-성공으로-연봉이-50-상승하다-4326',
  },
  {
    title: '솔루션 기업에서\n이커머스 대기업으로 이직했어요.',
    comments: (
      <>
        실무 역량 강화 프로그램에 참여하면서 이력서 피드백을 정말 많이 받았어요.{' '}
        <strong>여러 시니어 코치님</strong>들께 이력서를 여러 번 보여드리며 계속
        수정에 수정을 거듭했죠. 이런 과정을 거쳐 이력서의 완성도를 높일 수
        있었고, 결국 면접에서 완성도 높은 이력서가{' '}
        <strong>면접에서 좋은 평가</strong>를 받는 데 큰 역할을 했어요.
      </>
    ),
    logContent: '카드 2 (김종현 님)',
    course: '항해 플러스 프론트엔드 코스',
    buttonTitle: '3기 김종현님 인터뷰 읽어보기',
    image: createCDNUrl('/hanghae99/landing/hub/interview_2.webp'),
    link: 'https://hanghae99.spartacodingclub.kr/blog/3년-차-개발자의-대기업-이직의-비결-44487',
  },
  {
    title: '개발자로서 자신감과\n확실한 성장 방향을 찾았어요.',
    comments: (
      <>
        4년간 개발자로 일했지만 진정한 성장을 못했다고 느꼈어요. &apos;이 일의
        의미는 뭘까?&apos;, &apos;방향이 맞는걸까?&apos;, &apos;앞으로 어떻게
        해야 하지?&apos; 이런 고민을 하시는 주니어, 신입 개발자분들이라면
        항해99에서 함께하셨으면 좋겠어요. <strong>코치님들의 멘토링</strong>과{' '}
        <strong>동기와의 코드 리뷰</strong>를 통해 새로운 방향을 찾고,
        개발자로서의 확신을 가질 수 있을 거예요. 제가 그랬던 것처럼요.
      </>
    ),
    logContent: '카드 3 (천동민 님)',
    course: '항해 플러스 백엔드 코스',
    buttonTitle: '6기 천동민님 인터뷰 읽어보기',
    image: createCDNUrl('/hanghae99/landing/hub/interview_3.webp'),
    link: 'https://hanghae99.spartacodingclub.kr/blog/우수-수료생이-말하는-항해-플러스가-남긴-것들-37350',
  },
];
