import { useIsDesktop } from '@/hooks/common/useIsDesktop';
import { createCDNUrl } from '@/lib/utils/url';

import * as S from './style';

export default function FlameBackGroundTemplate() {
  const isDesktop = useIsDesktop();
  const videoSourse = isDesktop
    ? 'hh_hub_bg_flame_pc.mp4'
    : 'hh_hub_bg_flame_mo.mp4';

  return (
    <S.Container>
      <video
        playsInline
        autoPlay
        muted
        loop
        controls={false}
        src={createCDNUrl(`/hanghae99/landing/hub/${videoSourse}`)}
        controlsList='nodownload'
      />
    </S.Container>
  );
}
