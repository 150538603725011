import styled from '@emotion/styled';
import { captionSb, subTitle2 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

export const Container = styled.section`
  width: 100%;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);

  /* Blur/Primary */
  backdrop-filter: blur(6px);

  ${DESKTOP} {
    padding: 80px 0;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;

  ${DESKTOP} {
    margin-bottom: 40px;
  }
`;
export const Banner = styled.div`
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(6px);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: calc(100% - 21px);
  margin: 40px 16px 0 16px;

  ${DESKTOP} {
    width: 808px;
    padding: 20px 24px;
    margin: 60px 0 0 0;
  }
`;
export const Message = styled.div`
  color: ${vars.neutral[10]};
  ${captionSb};

  ${DESKTOP} {
    ${subTitle2};
  }
`;
