import styled from '@emotion/styled';
import {
  bodyCompact,
  captionSb,
  largeTitle,
  subTitle2,
  subTitle3,
  tag2,
  title2,
} from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 16px;
  background: rgba(0, 0, 0, 0.4);

  backdrop-filter: blur(10px);

  ${DESKTOP} {
    padding: 100px 24px;
  }
`;

export const Title = styled.div`
  color: ${vars.neutral[10]};
  text-align: center;

  ${title2}

  ${DESKTOP} {
    ${largeTitle};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  padding-top: 40px;

  ${DESKTOP} {
    flex-direction: row;
    padding-top: 60px;
    width: 100%;
    max-width: 1149px;
    justify-content: space-between;
    margin: 0 auto;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 343px;
  min-height: 274px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const CardTitle = styled.div`
  color: ${vars.text.secondary};
  ${subTitle3};

  margin-top: 12px;
  white-space: pre-line;

  ${DESKTOP} {
    ${subTitle2};
  }
`;

export const CardDescription = styled.div`
  margin-top: 12px;
  color: ${vars.text.quaternary};
  ${bodyCompact};

  word-break: keep-all;
  strong {
    color: ${vars.text.secondary};
  }
  ${DESKTOP} {
    height: 132px;
  }
`;

export const CardButton = styled.button`
  border-radius: 10px;
  border: 1px solid ${vars.line.nonClickable};
  background: rgba(255, 255, 255, 0.06);

  display: flex;
  padding: 8px 12px 8px 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  margin-top: 20px;
`;

export const CardButtonImage = styled.img`
  width: 72px;
  height: 44px;
`;

export const CardButtonTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const CardButtonTitle = styled.div`
  color: ${vars.neutral[20]};

  ${captionSb};
`;

export const CourseName = styled.div`
  color: ${vars.neutral[50]};

  ${tag2};
`;
