import { useIsDesktop } from '@/hooks/common/useIsDesktop';

const DURATION = 0.5;
const SMALL_SCALE = 0.8;
const OPACITY = 0.4;

function getLineState(
  currentState: number,
  lineIndex: number,
): 'upcoming' | 'active' | 'recent' | 'past' {
  if (currentState < lineIndex) {
    return 'upcoming';
  }
  if (currentState === lineIndex) {
    return 'active';
  }
  if (currentState === lineIndex + 1) {
    return 'recent';
  }
  return 'past';
}

export const useGetLineEffect = (currentState: number) => {
  const isDesktop = useIsDesktop();
  const SMALL_Y = isDesktop ? -60 : currentState >= 2 ? -50 : -40;

  /**
   * 아직 차례가 오지 않은 라인의 효과
   */
  const upcoming = {
    opacity: 0,
    y: 0,
    scale: 1,
  };

  /**
   * 현재 차례의 라인 (중앙에 크게 보이는 라인)의 효과
   */
  const active = {
    opacity: 1,
    y: 0,
    scale: 1,
  };

  /**
   * 메인 라인의 위에 옅고 작게 보이는 라인의 효과
   */
  const recent = {
    opacity: OPACITY,
    y: SMALL_Y,
    scale: SMALL_SCALE,
  };

  /**
   * 사라지 라인의 효과
   */
  const past = {
    opacity: 0,
    y: SMALL_Y,
    scale: SMALL_SCALE,
  };

  function getEffect(lineIndex: number) {
    const lineState = getLineState(currentState, lineIndex);

    const effectMap = {
      upcoming: upcoming,
      active: active,
      recent: recent,
      past: past,
    };

    const effect = effectMap[lineState];

    return {
      initial: effect,
      animate: effect,
      transition: { duration: DURATION },
    };
  }

  return getEffect;
};
