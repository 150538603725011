import { captionM, captionSb, subTitle1 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';
import { DESKTOP } from '@/styles/themes';
import styled from '@emotion/styled';

export const Card = styled.div`
  border-radius: 20px;
  border: 1px solid ${vars.line.clickable};
  background: linear-gradient(180deg, #141617 0%, #141617 100%);
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  width: 343px;
  ${DESKTOP} {
    display: flex;
    width: 270px;

    flex-direction: column;
    align-items: center;
  }
`;
export const Content = styled.div`
  width: 100%;
  z-index: 2;
`;
export const Title = styled.div`
  margin-top: 102px;
  color: ${vars.neutral[10]};
  ${subTitle1};
  white-space: pre-wrap;
`;

export const Description = styled.div`
  color: ${vars.text.quaternary};
  ${captionM};
  margin-top: 8px;
  white-space: pre-wrap;
  width: 100%;
`;

export const Image = styled.div`
  img {
    width: 343px;
    height: 157px;
  }

  ${DESKTOP} {
    img {
      width: 546px;
      height: 250px;
    }
  }
`;

export const Badges = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
  position: absolute;
  top: 20px;
  left: 16px;
`;

export const Badge = styled.div<{ color: string }>`
  display: flex;
  padding: 1px 6px;
  order-radius: 4px;
  border-radius: 4px;

  backdrop-filter: blur(6px);
  background-color: ${({ color }) => {
    // Convert hex to rgba
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.3)`;
  }};

  ${captionSb};
  color: ${({ color }) => color};
`;

export const Traits = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 0;
  margin-bottom: 32px;
`;

export const Trait = styled.div`
  display: flex;
  color: ${vars.neutral[30]};
  text-align: center;
  gap: 6px;

  ${captionSb};
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  display: flex;
  justify-content: center;
  overflow: hidden;
`;
