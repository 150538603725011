import * as Checkbox from '@radix-ui/react-checkbox';
import * as Dialog from '@radix-ui/react-dialog';
import { Spacer } from '@teamsparta/react';
import { Button } from '@teamsparta/stack-button';
import { StackThemeProvider } from '@teamsparta/stack-core';
import { Flex } from '@teamsparta/stack-flex';
import { CheckLine, NavigationClose } from '@teamsparta/stack-icons';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { isServer } from '@teamsparta/utils';
import { useState } from 'react';

import { useIsDesktop } from '@/hooks';

import * as S from './style';

export function MarketingAgreementModal({
  opened,
  isMutating,
  onConfirm,
  onClose,
}: {
  opened: boolean;
  onConfirm: () => void;
  onClose: () => void;
  isMutating: boolean;
}) {
  const [isAgreed, setIsAgreed] = useState(true);

  const isDesktop = useIsDesktop();

  return (
    <Dialog.Root
      open={opened}
      onOpenChange={() => {
        if (isMutating) {
          return;
        }

        onClose();
      }}
    >
      <Dialog.Portal
        container={isServer() ? null : document.getElementById('portal')}
      >
        <StackThemeProvider theme='hanghaeLight'>
          <Dialog.Overlay asChild>
            <S.Overlay
              variants={S.overlayVariants}
              initial='initial'
              animate='animate'
              exit='exit'
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            />
          </Dialog.Overlay>
          <Dialog.Content asChild>
            <S.Content
              key={isDesktop ? 'desktop' : 'mobile'}
              variants={isDesktop ? S.desktopVariants : S.mobileVariants}
              initial='initial'
              animate='animate'
              exit='exit'
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <Flex.Row justify='between' fullWidth>
                <Dialog.Title asChild>
                  <Text
                    as='h3'
                    font={{ mobile: 'subTitle2', desktop: 'subTitle1' }}
                    color={vars.text.primary}
                  >
                    개발자 이벤트 소식을 받아보고 싶다면,
                    <br />
                    마케팅 정보 수신에 동의해 주세요
                  </Text>
                </Dialog.Title>

                <Dialog.Close asChild>
                  <S.CloseButton>
                    <NavigationClose size={16} color={vars.icon.quaternary} />
                  </S.CloseButton>
                </Dialog.Close>
              </Flex.Row>

              <Spacer size={16} />

              <Flex align='center' gap={8}>
                <Checkbox.Root
                  id='marketing-agreement'
                  checked={isAgreed}
                  asChild
                  disabled={isMutating}
                  onCheckedChange={(checked) => {
                    if (checked === 'indeterminate') {
                      return;
                    }

                    setIsAgreed(checked);
                  }}
                >
                  <S.CheckboxContainer>
                    <Checkbox.CheckboxIndicator asChild>
                      <CheckLine size={16} color={vars.neutral[0]} />
                    </Checkbox.CheckboxIndicator>
                  </S.CheckboxContainer>
                </Checkbox.Root>

                <Text
                  as='label'
                  htmlFor='marketing-agreement'
                  font='bodyM'
                  color={vars.text.secondary}
                >
                  [선택] 마케팅 수신 동의
                </Text>
              </Flex>

              <Spacer size={24} />

              <Button
                disabled={!isAgreed}
                fullWidth
                onClick={onConfirm}
                size='lg'
                loading={isMutating}
              >
                동의하고 알림 받기
              </Button>

              <Spacer size={16} />

              <Text
                as='p'
                font='captionM'
                color={vars.text.quaternary}
                align='center'
              >
                *팀스파르타 서비스의 광고성 정보 수신 동의로 간주됩니다.
              </Text>
            </S.Content>
          </Dialog.Content>
        </StackThemeProvider>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
