export type RollingCard = {
  title: string;
  description: string;
  backgroundColor: string;
  image: string;
};

export const rollingCards: RollingCard[] = [
  {
    title: '성장에 대한 열정과 태도가 검증된\n현직 개발자 동기 2,000명+',
    description:
      '성장 욕구가 인증된 동료들과 상호 피드백 세션, 기술 행사, 네트워킹 파티에 함께하며, 고민을 나누고 자극을 받으며 함께 성장합니다.',
    backgroundColor: '#2E1B1B',
    image: '/hanghae99/landing/hub/card_1.webp',
  },
  {
    title: '지식 공유에 진심인\n현직 탑티어 시니어 코치진',
    description:
      '개발 문화를 선도하는 기업에 재직 중인 코치진이 강의와 피드백을 통해 성장 방향을 가이드합니다. 1:1 코드 리뷰, 이직 코칭도 진행하며 회사 바깥의 사수가 되어줍니다.',
    backgroundColor: '#151D2F',
    image: '/hanghae99/landing/hub/card_2.webp',
  },
  {
    title: '성장을 독려하는\n선배 기수와 운영진',
    description:
      '우수한 선배 크루원들이 학습 프로그램에 페이스 메이커로서 함께 참여합니다. 운영진을 통해 크루원은 의지를 다잡고, 혼자서는 막막한 과제도 끝까지 해내게 됩니다.',
    backgroundColor: '#1C252C',
    image: '/hanghae99/landing/hub/card_3.webp',
  },
];
