import styled from '@emotion/styled';
import { largeTitle, title2 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  padding: 80px 16px;
  width: 100%;

  ${DESKTOP} {
    padding: 100px 24px;
    gap: 60px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Title = styled.div`
  color: ${vars.neutral[10]};
  text-align: center;
  ${title2};
  ${DESKTOP} {
    text-align: left;
    width: 546px;
    ${largeTitle};
    position: sticky;
    top: 100px;
    height: 200px;
  }
`;

export const RightCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${DESKTOP} {
    gap: 24px;
  }
`;
