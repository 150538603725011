import { css } from '@emotion/react';
import { heroTitle } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';
import { DESKTOP } from '@/styles/themes';
import styled from '@emotion/styled';

const commonHeight = css`
  height: 1700px;
  ${DESKTOP} {
    height: 2300px;
  }
`;

export const Container = styled.div`
  width: 100%;
  overflow: visible;

  /* 스크롤바 숨기기 */
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera 대응 */
  }

  ${commonHeight};
`;

export const TextContainer = styled.div`
  width: 100%;
  z-index: 10;
  text-align: center;
  position: sticky;
  top: 50svh;
  height: 300px;
`;

export const Text = styled.div`
  position: absolute;
  color: ${vars.text.primary};
  width: 100%;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  ${DESKTOP} {
    ${heroTitle};
    gap: 16px;
  }
`;
export const Row = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 4px;
  ${DESKTOP} {
    ${heroTitle};
    gap: 16px;
    margin-left: 6px;
  }
`;

export const LogoWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 77px;
  height: 24px;

  ${DESKTOP} {
    width: 128.75px;
    height: 40px;
  }
`;
export const RollingBackground = styled.div`
  width: 100%;
  position: relative;
  transform: translateY(-300px);
  overflow: hidden;
  ${commonHeight};
  ${DESKTOP} {
    transform: translateY(-300px);
  }
`;

export const Background = styled.div`
  width: 100%;
  img {
    width: 100%;
    ${commonHeight};
    object-fit: contain;
    display: block;
  }
  ${DESKTOP} {
    img {
      ${commonHeight};
    }
  }
`;
