import Card from './components/Card';
import { rollingCards } from './data';
import * as S from './style';

export default function Companion() {
  return (
    <S.Container>
      <S.Title>
        좋은 개발자의 길로 이끌어줄 <br />
        동료들을 만나보세요
      </S.Title>
      <S.RightCards>
        {rollingCards.map((cards) => (
          <Card {...cards} key={cards.title} />
        ))}
      </S.RightCards>
    </S.Container>
  );
}
