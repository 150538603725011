export const title = {
  prefix: '혼자 듣는 온라인 강의만으론\n',
  highlight: '좋은 개발자',
  suffix: '가 되기 어렵습니다.',
};

export const description = '';

export const learningLimitations = [
  {
    limitation: '토론과 피드백 부재',
    consequence: '사고력 확장 한계',
  },
  {
    limitation: '팀 프로젝트의 부재',
    consequence: '협업 능력 고착화',
  },
  {
    limitation: '동기부여의 부재',
    consequence: '학습 의지 저하',
  },
];

export const description2 = '';
